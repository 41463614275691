import { template as template_bf11ec81aa26429682e9b1b7f31df86b } from "@ember/template-compiler";
const SidebarSectionMessage = template_bf11ec81aa26429682e9b1b7f31df86b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
