import { template as template_c85540b9bc6c4b7caed6094cc056d12d } from "@ember/template-compiler";
const FKControlMenuContainer = template_c85540b9bc6c4b7caed6094cc056d12d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
