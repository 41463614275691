import { template as template_f29300a9c8bb4eef82050b70a72915d5 } from "@ember/template-compiler";
const WelcomeHeader = template_f29300a9c8bb4eef82050b70a72915d5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
